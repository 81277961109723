/* -- HEADER -- */
@media (min-width: 1000px) {
    #header {
        height: 60px;
        width: 100vw;
        background: #283845;
        padding: 0;
        top: 0;
        display: flex;
        justify-content: baseline;
        position: relative;
        align-items: center;
        box-shadow: 0 3px 8px 2px #0006;
        z-index: 11;
    }

   #header{
        width: 100vw;
        margin-inline: auto;
        height: 120px;
    }

    #impressPage #header{
        position: fixed;
        top: 0;
        left: 0;
    }

    #header #toggleDropMenu,
    #header #toggleDropMenu input {
        display: none;
    }

    #logoLink {
        height: 60px;
        display: inline-block;
    }

    #logoLink img {
        height: 50px;
        margin: 5px 20px;
    }

    #headerImg{
        height: clamp(120px, 10vw, 170px);
        position: relative;
margin: 0 auto;
    }


    #header #headerRightSide {
        position: relative;
        white-space: nowrap;
        margin: 0 auto;
    }

    #header #headerRightSide a {
        padding: 30px 50px;
        margin: 0px 30px 0 0;
        color: #eee;
        text-decoration: none;
        font-size: 22px;
        transition: .3s;
        position: relative;
        z-index: 2;
        background-color: #283845;
        overflow: visible;
    }

    #header #headerRightSide a:before {
        content: '';
        background-color: #fff;
        z-index: 2;
        position: absolute;
        bottom: 25px;
        width: 3px;
        border-radius: 99px;
        height: 3px;
        left: 50%;
        transform: translateX(-50%);
        transition: .3s;
    }

    #header #headerRightSide a:after {
        content: '';
        z-index: -1;
        position: absolute;
        height: calc(100% - 8px);
        width: calc(100% - 8px);
        top: 2px;
        left: 2px;
        border: 2px solid #ccc;
        transition: .3s;
        border-radius: 5px;
    }

    #header #headerRightSide a:hover {
        background-color: #0ff0;
    }

    #header #headerRightSide a:hover::before {
        width: 50%;
    }

    
    /* -- Footer -- */

    #footer {
        position: relative;
        bottom: 0;
        min-height: 300px;
        background-color: #182835;
        width: 100%;
        padding-bottom: 30px;
    }

    #footer #socials {
        height: 60px;
        display: table;
        padding: 20px;
        margin: 0 auto;
        position: relative;
        border-bottom: 1px solid #fff;
        margin-bottom: 30px;
    }


    #footer #socials a {
        --hover-bc: #777;
        height: 60px;
        width: 60px;
        display: inline-block;
        border: 1px solid #fff;
        position: relative;
        box-shadow: inset 0 0 8px 2px #0007;
        margin: 0 20px;
        border-radius: 100px;
        transition: .4s;
    }

    #footer #socials a:hover {
        background-color: var(--hover-bc);
    }

    #footer #socials a i {
        font-size: 28px;
        color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    #footerInline{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    #footerImg {
        height: 300px;
    }

    #footerVerticalHr {
        height: 300px;
        width: 1px;
        position: relative;
        margin: 0 30px;
    }

    #copyright {
        position: relative;
        text-align: left;
        margin-left: 50px;
        color: #ccc;
    }

    #footerBottom a {
        color: #ccc;
        display: block;
        text-align: center;
    }

}

@media (max-width: 999px) {
        
    #header {
        width: 100vw;
        height: 100px;
        background: #283845;
        position: relative;
        isolation: isolate;
        box-shadow: 0 3px 8px 2px #0006;
        z-index: 11;
        width: 100vw;
        padding: 0;
        display: flex;
        justify-content: baseline;
        align-items: center;
        box-shadow: 0 3px 8px 2px #0006;
        z-index: 11;
    }

    #impressPage #header{
        position: fixed;
        top: 0;
        left: 0;
    }


    #headerImg{
        height: 110px !important;
        position: relative;
        margin-left: 15px !important;
        }


    #header #toggleDropMenu {
        color: #fff;
        position: absolute;
        top: 50%;
        right: 30px;
        font-size: 24px;
        transform: translateY(-50%);
        z-index: 1111111;
    }

    #header #toggleDropMenu input {
        appearance: none;
    }

    #header .dropMenu {
        width: 100vw !important;
        position: absolute !important;
        top: 100px;
        background-color: #283845;
        max-height: 0;
        transition: max-height .6s;
        overflow: hidden;
        z-index: -1;
    }

    .dropMenuActive {
        max-height: 400px !important;
        border-bottom-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        margin-left: 0 !important;
    }

    #header .dropMenu a {
        display: block;
        width: 100vw;
        padding: 25px 0px;
        margin: 0px 30px 0 0;
        color: #eee;
        text-decoration: none;
        font-size: 18px;
        transition: .3s;
        position: relative;
        text-align: center;
    }



    #footer {
        position: relative;
        bottom: 0;
        min-height: 300px;
        background-color: #182835;    
        width: 100vw;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    #footer #socials a:hover {
        background-color: var(--hover-bc);
    }

    #footer #socials a i {
        font-size: 28px;
        color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    #footerBottom a {
        color: #ccc;
        display: block;
        text-align: center;
    }


    
    #footer #socials {
        min-height: 60px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px 10px;
        padding: 20px;
        margin: 0 auto;
        position: relative;
    }


    #footer #socials::after {
        content: '';
        height: 1px;
        width: 300px;
        position: absolute;
        background-color: #eee;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
    }

    #footer #socials a {
        --hover-bc: #777;
        height: 60px;
        width: 60px;
        display: inline-block;
        border: 1px solid #fff;
        position: relative;
        box-shadow: inset 0 0 8px 2px #0007;
        margin: 0 10px;
        border-radius: 100px;
        transition: .4s;
    }

    #footer #socials a:focus {
        background-color: var(--hover-bc);
    }


    #footer #socials a i {
        font-size: 30px;
        color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    #footerInline {
        width: 80vw;
        margin: 0 auto;
        text-align: center;
    }


    #footerImg {
        width: 80vw;
        max-width: 600px;
        position: relative;
        margin-top: 20px;
    }


    

    #copyright {
        position: relative;
        width: 100%;
        color: #ccc;
        text-align: center;
        padding: 0 10px;
    }

    #footerBottom {
        position: relative;
        margin: 10px auto;
        width: 100%;
    }


    #footerBottom a {
        color: #ccc;
        display: block;
        text-align: center;
    }
}