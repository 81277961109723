#impressPage{
    padding: 60px 60px;
    margin-bottom: 50px;
}

#impressPage .pageTitle{
    --bc-gradient: linear-gradient(90deg, #164C8D, #2171D4)

}

#impressPage h2, p, strong, span{
    font-family: var(--pFont);
}


h2{
    margin-bottom: 20px;
}

#impressPage p{
margin: 0 20px;
font-size: 18px;
}

#impress{
    position: relative;
    max-width: 1200px;
}

#impress img{
    width: clamp(300px, 500px, 60%);
    height: auto;
    position: absolute;
    top: 50%;
    right: 00px;
    translate: 0 -50%;
    border-radius: 16px;
    background: #182835 !important;
    box-shadow:  0 0 12px -1px #0009 !important;
    padding: 20px 30px;
}

strong{
    display: block;
    margin-block: 30px 5px;
    font-size: 18px;

}

#impressPage #headerImg{
    height: 170px;
    position: relative;
    margin-left: 200px;
}


#impressPage #header #headerRightSide {
    position: relative;
    margin-left: 600px;
}

strong:nth-child(2){
    margin-block: 10px 5px;
}

#disclaimer h3 {
    margin-block: 20px 10px;
    font-size: 20px;
    font-family: var(--pFont);
}

#disclaimer p{
    line-height: 1.3em;
    font-size: 18px;
    max-width: 1300px;
}


@media (max-width:1100px) {


    #impressPage{
        padding: 80px 20px;
        margin-bottom: 50px;
    }

    #impress img{
        width: clamp(300px, 500px, 60%);
        height: auto;
        position: relative;
        margin: 0 auto;
        left: 15px;
        translate: 0 0 ;
        margin-top: 30px;
    }

    #disclaimer p{
        text-align: left;
        color: #444;
    }

    #impressPage p{
        margin: 0 5px;
        font-size: 16px;
    }

    #impressPage h2{
        font-size: 20px;
        margin-bottom: 10px;
    }
}