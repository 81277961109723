@media (min-width: 1001px) 
{
    #page:has(#Home){
    padding: 0 !important;
}

#homeIntro{
    overflow: hidden;
    width: 100vw;
    aspect-ratio: 16/9 !important;
    max-height: 100vh;
    background: no-repeat url("../../Images/pexels-mark-mccammon-2724749.jpg");
    background-size: 100%;
    background-position: center center;
    filter: saturate(2);
    display: flex;
    align-items: center;
    z-index: 111111;
    position: relative;
    top: 0 !important;
}

#page:has(#mailSent){
    padding: 0 !important;
}

#mailSent #about{
    min-height: 50vh;
}

#homeIntro div:not(.scroll){
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    height: 500px;
    width: 100vw;
    padding: 20px 100px;
    gap: 20px 0px;
    left: 0;
    background-color: #000a;
    box-shadow: 0 0 10px -1px #0008;
    color: #fff;
}

#headLogo{
    position: relative;
    margin: 0;
    margin-right: -100px;
    margin-left: 50px;
    display: inline;
    min-width: 400px;
    width: 50vw;
    max-width: 700px;


    left: -150px;
    top: 10px
}

#homeIntro div hr{
    position: absolute;
    height: 70%;

}

.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #eee;
	border-radius: 50%;
	position: absolute;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite !important;
    bottom: 50px;
    left: calc(50% - 30px);
    transition: background-color .3s;
    z-index: 111111111111 !important;
}

.scroll:hover{
    background-color: #eee3;
}

    .scroll::before{
        content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
        z-index: 11111111;
		border-left: 2px solid #eee;
  	    border-bottom: 2px solid #eee;
		transform: rotate(-45deg);
    }

    
    


#homeIntro div p{
    position: relative;
    margin-left: 50px;
    font-size:28px;
    font-family: 'montserrat';
    margin-bottom: 20px;
    line-height: 2em;
    top: 20px;
}

/*

--rich-black: #171a21;
--caribbean-current: #00667a;
--indigo-dye: #14427b;
--french-gray: #c8c6d7;
--myrtle-green: #477071;

*/

#about{
    position: relative;
    padding: 20px 60px ;
}

#about p{
    margin-bottom: 0;
}

#about h1{
    --bc-gradient: linear-gradient(90deg, #164C8D, #2171D4)
}

#services h1{
    font-size: 42px;
    margin: 0px 80px;
    margin-bottom: 30px;
    --bc-gradient: linear-gradient(90deg, #164C8D, #2171D4)
}


#services div{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.angebot{
    width: 600px;
    position: relative;
    margin: 20px 40px;
    max-height: 337.5px;
    overflow: hidden;
    box-shadow: 0 0 12px -1px #0007;
    transition: .6s !important;
}

.angebot:hover{
    max-height: 800px;
}

.angebot .angebot_head{
    width: 600px;
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.angebot .angebot_head img {
    width: 600px;
    position: absolute;
}

.angebot .angebot_head h2{
    position: absolute;
    color: #fff;
    font-family: var(--pFont);
    bottom: 0px;
    font-size: 32px;
    width: 100%;
    text-align: right;
    padding: 5px 30px;
    padding-bottom: 10px;
    background-color: #0007;
    box-shadow: 0 -2px 6px 3px #0007;
    margin-bottom: 0;
}



.angebot .angebot_body{
    max-height: calc(800px - 337.5px);
    padding: 30px 40px;
    background-color: #f5f5f5;
    font-size: 18px;
}


.angebot .angebot_body p{
    font-family: var(--pFont);
}

.angebot .angebot_body p i{
    margin-block: 7px;
}

.angebot .angebot_body a{
    padding: 20px 30px;
    font-size: 18px;
    color: #005772;
    border: 1px solid #007792;
    text-decoration: none;
    margin-top: 30px;
    transition: .3s;
    box-shadow: 0 0 12px -1px #0008;
}


.angebot .angebot_body a:hover {
    background-color: #0097b2;
    color: #000;
    box-shadow: none;
}


/* --------------- Kontakt ------------------- */


    #Contact {
        padding: 20px 60px;
        background-color: #315d75;
        margin-top: 100px;
    }

    #Contact h1{
        --bc-gradient: #fff;
        color: #fff;
    }

    #Contact h1:hover::after{
        height: 5px;
    }

    #Contact > p{
        color: #fff;
    }
    #Contact p a{
        color: #1ac;
    }
    #contactForm {
        padding: 20px 50px;
        max-width: 1500px;
        position: relative;
    }

    .contactFormTb {
        padding: 10px 20px;
        margin: 5px 20px;
        margin-left: 30px;
        width: 95%;
        margin-bottom: 30px;
    }

    #contactForm p {
        width: 20%;
        margin-top: 15px;
        color: #fff;
    }

    .textarea {
        padding: 20px 20px;
        min-height: 200px;
        width: calc(95% + 30px);
        margin-top: 20px;
        max-width: 1300px;
    }



#contactForm {
    padding: 30px 60px;
    width: 100%;
    max-width: 1400px;
    position: relative;
    box-shadow: inset 0 0 13px 0px #0008;
    background-color: #214d65;
    margin: 20px auto;
}


.contactFormTb {
    font-size: 18px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #ddd;
    margin-bottom: 30px;
    font-family: var(--pFont);
    color: #eee;
}

.contactFormTb::placeholder, .textarea::placeholder{
    color: #ddd;
}

#contactForm p {
    font-size: 18px;
    font-family: var(--pFont)
}

.textarea {
    font-size: 18px;
    background-color: transparent;
    border: 1px solid #eee;
    resize: vertical;
    font-family: var(--pFont);
    color: #eee;
}

#sendMail {
    padding: 30px 50px;
    font-size: 18px;
    background-color: #416d85;
    color: #fff;
    border: none;
    text-decoration: none;
    margin: 30px auto;
    display: block;
    transition: .3s;
    box-shadow: 0 0 12px -1px #0008;
    cursor: pointer;
}

#sendMail:hover {
    background-color: #182835;
    box-shadow: none;
}

}

@keyframes down {
    0% {
        transform: translate(0);
    }
    10% {
        transform: translateY(15px);
    }
    30% {
        transform: translate(0);
    }
}

@media (max-width: 1000px) {
    #page{
        padding: 0 !important;
    }
    
    #homeIntro{
        overflow: hidden;
        width: 100vw;
        min-height: 50vh;
        aspect-ratio: 16/9;
        max-height: 100vh;
        background: no-repeat url("../../Images/pexels-mark-mccammon-2724749.jpg");
        background-size:  120vw 100vw;
        background-position: center center;
        filter: saturate(2);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    
    
    #mailSent #about{
        min-height: 50vh;
    }
    
    #homeIntro div:not(.scroll){
        position: relative;
        width: 100%;
        padding: 20px 20px;
        text-align: center;
        background-color: #000a;
        box-shadow: 0 0 10px -1px #0008;
        color: #fff;
    }
    
    #headLogo{
        position: relative;
        margin: 0;
        width: 80vw;
        max-width: 400px;
        top: -10px
    }
    
    #homeIntro div hr{
        display: none;
    
    }
    
    
    #homeIntro div p{
        position: relative;
        margin-left: 0px;
        font-size: 16px;
        font-family: 'montserrat';
        line-height: 2em;
        text-align: left;
    }
    

    
    #about{
        position: relative;
        padding: 20px 20px ;

    }
    
    #about p{
        margin-bottom: 0;
        font-size: 16px;
    }
    
    #about h1{
        position: relative;
        --bc-gradient: linear-gradient(90deg, #164C8D, #2171D4);
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    #services h1{
        font-size: 28px;
        margin: 0px 10px;
        margin-left: 20px;
        margin-bottom: 30px;
        white-space: nowrap;
        --bc-gradient: linear-gradient(90deg, #164C8D, #2171D4)
    }
    
    
    #services div{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .angebot{
        max-width: 450px;
        position: relative;
        margin: 20px 20px;
        max-height: 240px;
        overflow: hidden;
        box-shadow: 0 0 12px -1px #0007;
        transition: .6s !important;
    }
    
    .angebot:hover{
        max-height: 800px;
    }
    
    .angebot .angebot_head{
        width: 600px;
        height: 240px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }
    
    .angebot .angebot_head img {
        width: 500px;
        top: -40px;
        position: absolute;
    }
    
    .angebot .angebot_head h2{
        position: absolute;
        color: #fff;
        font-family: var(--pFont);
        bottom: 0px;
        margin-bottom: 0;
        font-size: 24px;
        width: 100%;
        text-align: right;
        padding: 5px 10px;
        padding-bottom: 10px;
        background-color: #0007;
        box-shadow: 0 -2px 6px 3px #0007;
    }
    
    
    
    .angebot .angebot_body{
        max-height: calc(800px - 337.5px);
        padding: 20px 25px;
        background-color: #f5f5f5;
        font-size: 16px;
    }
    
    #mailSent h1{ 
        font-size: 32px;
    }

    .angebot .angebot_body p{
        font-family: var(--pFont);
        color: #666;
        line-height: 1.3em;
        overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    }
    
    .angebot .angebot_body p i{
        margin-block: 7px;
    }
    
    .angebot .angebot_body a{
        padding: 20px 30px;
        font-size: 18px;
        color: #005772;
        border: 1px solid #007792;
        text-decoration: none;
        margin-top: 30px;
        transition: .3s;
        box-shadow: 0 0 12px -1px #0008;
    }
    
    
    .angebot .angebot_body a:hover {
        background-color: #0097b2;
        color: #000;
        box-shadow: none;
    }
    
    
    /* --------------- Kontakt ------------------- */
    
        #Contact {
            padding: 40px 20px;
            background-color: #315d75;
        }
    
        #Contact h1{
            --bc-gradient: #fff;
            color: #fff;
            margin-top: 0;
        }
    
        #Contact h1:hover::after{
            height: 5px;
        }
    
        #Contact > p{
            color: #fff;
            margin-bottom: 0;
        }
        #Contact p a{
            color: #1ac;
        }
        #contactForm {
            padding: 20px 10px;
            position: relative;
        }
    

        #contactForm p {
            width: 50%;
            margin-top: 15px;
            color: #fff;
        }
    

    
    #contactForm {
        padding: 10px 0px;
        width: 100%;
        max-width: 1400px;
        position: relative;
        margin: 20px auto;
    }
    
    
    .contactFormTb {
        font-size: 18px;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #ddd;
        margin-bottom: 30px;
        font-family: var(--pFont);
        color: #eee;
    }
    
    .contactFormTb::placeholder, .textarea::placeholder{
        color: #ddd;
    }
    
    #contactForm p {
        font-size: 16px;
        font-family: var(--pFont);

    }
    
    .textarea {
        font-size: 16px;
        background-color: transparent;
        border: 1px solid #eee;
        resize: vertical;
        font-family: var(--pFont);
        color: #eee;
    }
    
    #sendMail {
        padding: 30px 50px;
        font-size: 18px;
        background-color: #416d85;
        color: #fff;
        border: none;
        text-decoration: none;
        margin: 30px auto;
        display: block;
        transition: .3s;
        box-shadow: 0 0 12px -1px #0008;
        cursor: pointer;
    }
    
    #sendMail:hover {
        background-color: #182835;
        box-shadow: none;
    }
    
       
    
        #contactForm {
            margin-top: 0;
            padding: 20px 10px;
            position: relative;
        }
    
        #contactForm::before,
        #contactForm::after {
            left: 50%;
            transform: translateX(-50%);
        }
    
        .contactFormTb {
            padding: 10px 10px;
            margin: 15px auto;
            width: 100%;
            margin-bottom: 40px;
            border-bottom: 1.5px solid #ddd;
            font-size: 16px;
        }
    

    
        .textarea {
            padding: 20px 20px;
            min-height: 150px;
            width: 100%;
            margin-top: 10px;
            overflow-y: auto;
        }
    
}